import { Vue, Options } from "vue-class-component";
import axios from "axios";
import { axiosInstanceToken, setAuthToken } from "@/plugins/axios";
import { formatBytes } from "@/utils";
import { File } from "@/models/File";

@Options({})
export default class TwoFactorValidated extends Vue {
  loading = true;
  rows: File[] = [];
  projectName = "";
  isVerification = false;
  $cookies: any;
  email = "";

  declare verifications: any;

  get id(): string {
    return this.$route?.query?.id as string;
  }

  get cookieEmailName(): string {
    return `default_generate_code_info_${this.id}`;
  }

  async created(): Promise<void> {
    setAuthToken(this.id);
    await this.getFiles();
  }

  async getFiles(): Promise<void> {
    try {
      const { data } = await axiosInstanceToken.get(
        this.verifications.getFiles(this.id)
      );

      this.projectName = data.data.project.name;
      this.rows = data.data[this.verifications.files];

      this.loading = false;

      this.$cookies.remove(this.cookieEmailName);
    } catch (error: any) {
      if (error.response.status === 401) {
        this.isVerification = true;
      } else {
        this.$router.push({
          name: "Error",
          query: {
            type: "notValid",
          },
        });
      }
    }
  }

  formatBytesSize(val: number): string {
    return formatBytes(val);
  }
}
