import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "verification-code" }
const _hoisted_2 = ["id", "value", "onKeyup"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (item, index) => {
      return _withDirectives((_openBlock(), _createElementBlock("input", {
        id: `code_${index}`,
        key: index,
        type: "text",
        value: _ctx.value[index],
        class: "verification-code__input",
        onKeydown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onKeydown && _ctx.onKeydown(...args))),
        placeholder: "–",
        autocomplete: "off",
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput($event.target.value, $event.target.id))),
        onKeyup: _withKeys(($event: any) => (_ctx.onEnter(index)), ["enter"])
      }, null, 40, _hoisted_2)), [
        [_directive_focus, _ctx.isActive(`code_${index}`)]
      ])
    }), 128))
  ]))
}