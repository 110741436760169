
import Title from "@/components/Title.vue";
import VerificationInputCode from "@/components/VerificationInputCode/index.vue";
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import { axiosInstanceToken, setAuthToken } from "@/plugins/axios";

@Options({
  components: {
    Title,
    VerificationInputCode,
  },
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    verifications: {
      type: Object,
      default: "",
    },
  },
})
export default class Verification extends Vue {
  code = "";
  email = "";
  codeLength = 4;
  countDown = 0;
  timer = 30;

  id = "";

  $cookies: any;
  verifications: any;
  loading = false;
  applyCodeError = false;

  get cookieEmailName(): string {
    return `default_generate_code_info_${this.id}`;
  }

  get getEmail(): string {
    return this.$cookies.isKey(this.cookieEmailName)
      ? this.$cookies.get(this.cookieEmailName)
      : this.email;
  }

  async mounted(): Promise<void> {
    this.$emit("onMounted");

    await this.onGenerateCode();
  }

  onGenerateCode(): boolean | void {
    // if (this.$cookies.isKey(this.cookieEmailName)) return false;
    this.onGenerateCodeAction();
  }

  async onGenerateCodeAction(): Promise<void> {
    try {
      const { data } = await axios.get(
        this.verifications.generateCode(this.id)
      );
      this.email = data.data.email;
      this.setTimer(data.data.expireAt);

      this.$cookies.set(this.cookieEmailName, this.email, "30MIN");
    } catch (error) {
      this.countDown = this.timer;
      this.startCountDown();
    }
  }

  setTimer(expireAt: number): void {
    const date = expireAt - Date.now();

    this.countDown = new Date(date).getSeconds();

    this.startCountDown();
  }

  async onSendCodeAction(): Promise<void> {
    try {
      const { data } = await axios.get(this.verifications.sendCode(this.id));

      this.setTimer(data.data.expireAt);
    } catch (error: any) {
      // this.$router.push({
      //   name: "Error",
      //   query: {
      //     type: "notValid",
      //   },
      // });
    }
  }

  countDownTimer(): void {
    if (this.countDown > 0) {
      setTimeout(() => {
        this.countDown -= 1;
        this.countDownTimer();
      }, 1000);
    }
  }

  async onApplyCode(code: string): Promise<void> {
    this.loading = true;
    try {
      const data = await axiosInstanceToken.get(
        this.verifications.applyCode(this.id),
        {
          params: {
            code,
          },
        }
      );

      this.$cookies.set(this.id, data.data.data.token);

      setAuthToken(this.id);

      this.$emit("success");

      this.$cookies.remove(this.cookieEmailName);
    } catch (error: any) {
      this.applyCodeError = true;
    }
    this.loading = false;
  }

  onAction(): void {
    this.onApplyCode(this.code);
  }

  startCountDown(): void {
    this.countDownTimer();
  }

  onResent(): void {
    this.onSendCodeAction();
    this.startCountDown();
  }

  onCodeChanged(code: string): void {
    this.code = code.trim();

    if (this.applyCodeError) {
      this.applyCodeError = false;
    }
  }
}
