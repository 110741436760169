
import { Options, Vue } from "vue-class-component";
import Sorting from "@/components/Sorting/index.vue";

@Options({
  components: {
    Sorting,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
      required: true,
    },
    columns: {
      type: Array,
      default: () => [
        {
          label: "",
          key: "",
          customWidth: "",
        },
      ],
      required: true,
    },
    config: {
      type: Object,
      default: () => ({
        tableClass: "",
      }),
    },
  },
})
export default class Table extends Vue {
  rows: any[];
  columns: any[];
  config: any;

  get tableColumnsTemplate(): string {
    return this.columns
      .map((item) =>
        item.customWidth
          ? `minmax(${item.minWidth ?? 0}, ${item.customWidth})`
          : `minmax(${item.minWidth ?? "50px"}, 1fr)`
      )
      .join(" ");
  }

  get tableHasItems(): boolean {
    return this.rows.length > 0;
  }

  getDataByKey(object: { [x: string]: unknown }, key: string) {
    return object[key];
  }

  modifyStringToUnderline(string: string, key: string): string {
    return `${string}_${key.replace(".", "_")}`;
  }
}
