
import { Options, Vue } from "vue-class-component";

import { CustomSort } from "@/models/Sort";

@Options({
  components: {},
  props: {
    headerKey: {
      type: String,
      default: "",
    },
    sortSelected: {
      type: Object,
      default: (item: CustomSort) => item,
    },
  },
})
export default class Sorting extends Vue {
  headerKey: string;
  sortSelected: CustomSort;
}
