import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "custom-table__wrap custom-blue-scroll" }
const _hoisted_2 = {
  key: 0,
  class: "custom-table__content"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  class: "custom-table__placeholder"
}
const _hoisted_5 = { class: "custom-table__bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sorting = _resolveComponent("Sorting")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["custom-table", [_ctx.config.tableClass]])
  }, [
    _createElementVNode("ul", {
      class: "custom-table__template custom-table__header",
      style: _normalizeStyle({ gridTemplateColumns: _ctx.tableColumnsTemplate })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (header, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "custom-table__header-item",
          style: _normalizeStyle({ padding: header.customPadding })
        }, [
          _renderSlot(_ctx.$slots, _ctx.modifyStringToUnderline('column', header.key), { option: header }, () => [
            _createTextVNode(_toDisplayString(header.label), 1)
          ]),
          (header.sort)
            ? (_openBlock(), _createBlock(_component_Sorting, {
                key: 0,
                "header-key": header.key,
                "sort-selected": _ctx.config.sortSelected,
                onClickSortUp: ($event: any) => (
            _ctx.$emit('onToSortClick', { key: header.key, isAsc: true })
          ),
                onClickSortDown: ($event: any) => (
            _ctx.$emit('onToSortClick', { key: header.key, isAsc: false })
          )
              }, null, 8, ["header-key", "sort-selected", "onClickSortUp", "onClickSortDown"]))
            : _createCommentVNode("", true)
        ], 4))
      }), 128))
    ], 4),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.tableHasItems)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: row.id
              }, [
                _createElementVNode("div", {
                  class: "custom-table__template custom-table__row",
                  style: _normalizeStyle({ gridTemplateColumns: _ctx.tableColumnsTemplate })
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (header, key) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: key,
                      class: "custom-table__row-item",
                      onClick: ($event: any) => (header.clickStop ? {} : _ctx.$emit('onRowClick', row))
                    }, [
                      (!header.key.includes('buttons_action'))
                        ? _renderSlot(_ctx.$slots, _ctx.modifyStringToUnderline('row', header.key), {
                            key: 0,
                            option: { row, index }
                          }, () => [
                            _createTextVNode(_toDisplayString(_ctx.getDataByKey(row, header.key)), 1)
                          ])
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: "custom-table__row-buttons-wrap",
                            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                          }, [
                            _renderSlot(_ctx.$slots, header.key, {
                              option: { row, index }
                            })
                          ]))
                    ], 8, _hoisted_3))
                  }), 128))
                ], 4)
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "table-placeholder")
          ])),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "table-bottom")
      ])
    ])
  ], 2))
}