
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    fields: Number,
  },
  directives: {
    focus: {
      updated(el, binding) {
        el.tabIndex = -1;

        if (binding.value) {
          el.tabIndex = 0;
          el.focus();
        }
      },
    },
  },
})
export default class VerificationInputCode extends Vue {
  fields: number;
  value: string[] = [];
  id = "";

  get isActive() {
    return (elId: string) => elId === this.id;
  }

  mounted(): void {
    this.activeInput("code_0");
  }

  onKeydown(event: any): void {
    if (event.keyCode === 8 && event.target.value === "") {
      let [id, index] = event.target.id.split("_");
      this.value[+index as number] = "";
      this.activeInput(`${id}_${Number(index) - 1}`);

      this.onCodeChanged();
    }
  }

  activeInput(focusId: string): void {
    this.id = focusId;
  }

  onInput(value: string, inputId: string): void {
    let [id, index]: string[] = inputId.split("_");
    const [first, ...rest]: any = value.trim();

    value = first ?? "";

    const lastInputBox = +index > this.fields - 1;
    const insertedContent = first !== undefined;

    if (insertedContent && !lastInputBox) {
      this.value[+index as number] = value;
      this.activeInput(`${id}_${Number(index) + 1}`);

      this.onInput(rest, `${id}_${Number(index) + 1}`);
    }

    if (lastInputBox) {
      this.activeInput(`${id}_${this.fields - 1}`);
    }

    this.onCodeChanged();
  }

  onCodeChanged(): void {
    this.$emit("code", this.value.join(""));
  }

  onEnter(): void {
    if (this.value.join("").length) {
      this.$emit("onEnterEvent");
    }
  }
}
